import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['peopleFields', 'input', 'hidden', 'form', 'formButton']

  connect() {
    this.searchPeople();
    document.addEventListener("autocomplete.change", this.searchPeople.bind(this));
    super.connect();
  }

  searchPeople() {
    if (!this.hasInputTarget) { return }

    if (this.hiddenTarget.value == '') {
      this.formButtonTarget.disabled = true;

    } else {
      this.formButtonTarget.disabled = false;
    }
  }

}
