import ApplicationController from './application_controller';

export default class extends ApplicationController {

  initialize() {
    console.log('initialized!')
    document.addEventListener('stimulus-reflex:connected', () => {
      this.loadActivites(new Event('init'));
    })
  }

  loadActivites(event) {
    
    this.stimulate('PipeDriveActivitiesReflex#load_activites', document.querySelector('.pipe-drive-activities'));
  }

 
}
