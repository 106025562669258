import ApplicationController from './application_controller';
import Rails from "@rails/ujs"

export default class extends ApplicationController {

  static targets = ['searchInput', 'sortSelect', 'form', 'filterSelect', 'pagination', 'loadMore', 'listing', 'flag', 'tagSelect', 'noTags']

  initialize() {
    document.querySelector('#search').focus();
    window.addEventListener("load", () => {
      if (document.querySelector('#search').value.length > 0) {
        document.querySelector('#search').dispatchEvent(new InputEvent('input'));
      }
    });
  }
  toggleTags(event) {
    this.tagSelectTarget.disabled = this.noTagsTarget.checked;
    this.loadMore(event);
  }

  loadMore(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.spinnerOn();
    this.setRequestId();
    this.setActionSource(event.srcElement.id);
    this.pushQueryParamsToUrl(this.formTarget);
    Rails.fire(this.formTarget, 'submit');
  }

  setSort(event) {
    event.preventDefault();
    this.sortSelectTarget.value = event.currentTarget.dataset.sortBy;
    this.loadMore(event)
  }

  result(event) {
    let requestId = event.detail[0].body.querySelector('#request_id');
    if (requestId != undefined && this.requestId == requestId.value) {
      let actionSource = event.detail[0].body.querySelector('#action_source').value;
      var list = document.querySelector('.bubble #listing-installations');
      if (actionSource == 'pagination-link') {
        var installationSpans = event.detail[0].body.querySelectorAll('#listing-installations span.installation');
        installationSpans.forEach(function (installationSpan) {
          list.appendChild(installationSpan);
        });
      } else {
        var installationsList = event.detail[0].body.querySelector('body #listing-installations');
        list.parentElement.replaceChild(installationsList, list);
      }
      this.replaceElementWithNewBySingleQuery('div#pagination', event)
      this.replaceElementWithNewBySingleQuery('#outset', event)
      this.replaceElementWithNewBySingleQuery('#step', event)
      this.spinnerOff();
    }
  }
}
