import ApplicationController from './application_controller'

export default class extends ApplicationController {
static targets = ['countForm', 'purchasePriceInput', 'currencyInput']

  addHwPartCount(event) {
    event.preventDefault();
    let unitPrice = event.currentTarget.parentElement.parentElement.querySelector('#unit_price_unit_price').value;
    let currency = event.currentTarget.parentElement.parentElement.querySelector('#currency_currency').value;
    this.stimulate('StockReflex#add_hw_part_count', event.target, unitPrice, currency).then(() => {
      const hwPartCount = event.target.parentElement.parentElement.querySelector('.hw-part-count')
      const countInput = event.target.querySelector('.count-input')

      hwPartCount.innerHTML = parseInt(hwPartCount.innerHTML) + parseInt(countInput.value)
      countInput.value = 0
    })
  }

  addPurchasePrice(event) {
    this.stimulate('StockReflex#add_purchase_price', this.purchasePriceFormTarget)
  }

  addCurrency(event) {
    this.stimulate('StockReflex#add_currency', this.currencyFormTarget)
  }

  deleteHwPart(event) {
    event.preventDefault()
    if (!confirm(event.target.dataset.confirmMsg)) {
      return
    }
    this.stimulate('StockReflex#delete_hw_part', event.target, event.target.dataset.hwPartId).then(() => {
      const rowTodelete = event.target.closest('tr')
      rowTodelete.remove()
    });
  }
}
