import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['organizationsFields', 'input', 'hidden', 'form', 'formButton']

  connect() {
    this.searchOrganization();
    document.addEventListener("autocomplete.change", this.searchOrganization.bind(this));
    super.connect();
  }

  searchOrganization() {
    if (!this.hasInputTarget) { return }

    if (this.hiddenTarget.value == '') {
      this.formButtonTarget.disabled = true;

    } else {
      this.formButtonTarget.disabled = false;
    }
  }

}
