import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['companyFields', 'input', 'hidden', 'form', 'formButton']

  connect() {
    this.changeCompany();
    document.addEventListener("autocomplete.change", this.changeCompany.bind(this));
    super.connect();
  }

  changeCompany() {
    if (!this.hasInputTarget) { return }

    if ((event.detail && event.detail.value != "") && (this.hasHiddenTarget)) {
      this.hiddenTarget.value = event.detail.value;
      this.formTarget.action = this.formTarget.action.replace('NONE', this.hiddenTarget.value);
      this.formButtonTarget.disabled = false;
    }

    var disabled;

    // Don't send hidden input to the server. Otherwise it creates a new record instead of assigning the selected one.
    var inputs = this.companyFieldsTarget.getElementsByTagName('input');
    for (let input of inputs) { input.disabled = disabled; };
  }

}
